.Servicios {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0 2rem;
  margin-bottom: 6rem;
  min-height: 3000px;
}



.programs-header {
  display: flex;
  position: relative;
  gap: 5rem;
  font-weight: bold;
  font-size: 3rem;
  justify-content: center;
  color: white;
  text-transform: uppercase;
  font-family: Oswald;
  font-style: italic;
}



.programs-categories {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden;
  gap: 2rem;
}

.svg-service > svg {
  height: 2.5rem;
  fill: white;
  transform: translateX(-370px);
}


.category {
  display: flex;
  flex-direction: column;
  background-color: gray;
  padding: 2rem;
  gap: 1rem;
  color: white;
  flex: 1;
  justify-content: space-between;
  transition: all 1s;
  width: 80%;
  height: 300px;
  padding: 100px;
  border: 1px solid white;
}



.category > :nth-child(2) {
  font-size: 1.5rem;
  font-weight: bold;
  max-width: 15vw;
}

.category > :nth-child(3) {
  font-size: 1.15rem;
  line-height: 25px;
  max-width: 15vw;

}


.category:hover {
  background-color: rgb(221, 77, 77);

}

.pictures {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  position: absolute;
  left: 40%;
  bottom: 50%;
}
.programs-categories .pictures img {
  height: 100px;
  width: 200px;
  object-fit: cover;
}

.join-now {
  display: flex;
  align-items: center;
  gap: 1rem;
  position: absolute;
  top: 60%;
  left: 40%;
  margin-right: 15px;
}
.join-now:hover {
  cursor: pointer;
}

.join-now > img {
  width: 1rem;
}

@media screen and (max-width: 768px) {
  .Servicios {
    height: 3250px;
  }
  .programs-header {
      flex-direction: column;
      gap: 1rem;
      font-size: x-large;
      align-items: center;
      justify-content: center;
      margin-top: 2rem;
  }
  .programs-categories .pictures img {
    position: absolute;
    height: 80px;
    width: 100px;
    object-fit: cover;
    left: 60%;
    margin-left: 60px;

  }
  .pictures> :nth-child(1) {
    transform: translateY(-70px);
  }

  .pictures> :nth-child(2) {
    margin-left: 40px;
    transform: translateY(70px);
  }
  .join-now {
    position: absolute;
    top: 50%;
    left: 20%;
    max-height: 50%;
    margin-right: 15px;
  }

  .programs-categories {
      flex-direction: column;
  }
  .category {
      padding: 1.5rem;
      justify-content: center; /* Centers children vertically */
      min-width: 99%;
      gap: 0.5rem;
      min-height: 550px;
  }

  .category > :nth-child(2) {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  .category > :nth-child(3) {
    margin-top: 1rem;
    width: 80%;
  }
  .category > :nth-child(5) {
    display: none;  
  }
}

.animated-service-title {
  animation: scaleFont 1s;
  transform: scale(1.4);
}

@keyframes scaleFont {
  0% {
    transform: scale(1);
  } 100% {
    transform: scale(1.4);
  } 
}