section{
    padding:2rem 9%;
    background-color: #b8b8b8;
  }
.Footer-container {
  background-color: #b8b8b8;

}

.footer{
  border-top: 2px solid snow;

}

.footer .box-container {
    display: flex;
    flex-wrap: wrap;
    gap:1.5rem;
    font-size: 20px;
    justify-content: space-around;
    border-bottom: 5px solid snow;
  }

  .footer .box-container .box{
    padding:1rem;
    height: 380px;
    width: 350px;
    font-size: 10px;
  }
  
  .footer .box-container .box h3{
    font-size: 2.5rem;
    padding:.7rem 0;
    color:var(--darkGrey);
  }
  
  .footer .box-container .box p{
    padding:.7rem 0;
    color:var(--darkGrey);
  }
  
  .footer .box-container .box a{
    display: block;
    padding:.7rem 0;
    color:var(--darkGrey);
  }
  
  .footer .box-container .box a:hover{
    color:var(--orange);
    text-decoration: underline;
  }
  
  h3,a{
    text-decoration: none;
    font-size: 15px;
  }


.box>img{
    padding: 0.5rem;
}

.box>img:hover{
    cursor: pointer;
}

.credits{
    text-align: center;
}

.credits>p {
  color: var(--darkGrey);
}

@media screen and ( max-width: 768px){
    .footer .box-container{
        flex-direction: column;
        gap: 1rem;
        border: none;
    }
    .footer .box-container .box:nth-child(1){
      padding: 0px;
        height: 200px;
    }
    .footer .box-container .box:nth-child(2){
        height: 200px;
        margin-top: 5rem;

    }
    .footer .box-container .box:nth-child(3){
        height: 200px;
        margin-top: 5rem;
    }
    .credits{
        margin-top: 7rem;
        padding-top: 7rem;
        color:var(--darkGrey);
        font-size: 15px;
    }
}



  