.hero {
  display: flex;
  justify-content: space-between;
  margin-bottom: 13rem;
}

.right-h {
  background: conic-gradient(#ff0000a2, #e46c5779, #ce583081, #ff0000a2);
  flex: 1;
  position: relative;
  border-radius: 5px;
  width: 28%;
}

.hero-blur {
  width: 22rem;
  height: 30rem;
  left: 0;
}

.float{
  position: fixed;
  width: 60px;
  height: 60px;
  right: 30px;
  bottom: 40px;
  background-color: #25d366;
  font-size: 30px;
  padding-top: 11px;
  color: white;
  font-weight: 500px;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

@keyframes moveSpace {
  0% {
    transform: translateX(-100px);
  }
  100% {
    transform: translateX(0);
  }
}

.text-animated {
  font-size: 90px;
  letter-spacing: 8px;
  display: inline-block;
  animation: moveSpace 3s ease-in-out;
}

@keyframes scaleUp {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.stroke-text-animated {
  display: inline-block;
}


.best-point {
  position: absolute;
  background-color: #f45c16;
  width: 25px;
  height: 25px;
  left: -35px;
  z-index: 1;
  border-radius: 3rem;
  background-image: conic-gradient(#393d42, #2f3031, #3c4147, #2d3641, #393d42);
  border-radius: 360px;
}

.hero-text {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-transform: uppercase;
  font-size: 5rem;
  font-weight: bold;
  color: rgb(255, 255, 255);
  text-overflow: inherit;
  width: 100%;
  text-align: center;
  margin-top: 150px;
}

.hero-text > div:nth-of-type(3) {
  font-size: 1rem;
  font-weight: 200;
  text-transform: none;
  letter-spacing: 1px;
  width: 80%;
}
.figures {
  display: flex;
  gap: 1.5rem;
  width: 80%;
  justify-content: center;
}
.figures > div {
  display: flex;
  flex-direction: column;
}
.figures > div > span:nth-of-type(1) {
  color: white;
  font-size: 2rem;
}
.figures > div > span:nth-of-type(2) {
  color: var(--gray);
  text-transform: uppercase;
}



/* LEFT-H Division of hero section */
.left-h {
  padding: 2rem;
  flex: 4;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  animation: scaleUp 1.5s ease-in-out;
  margin-top: 6vh;
  width: 70%;
}

.the-best {
  position: relative;
  margin-top: 1rem;
  align-self: end;
  margin-right: 20%;
  margin-bottom: 0.5rem;
  width: fit-content;
  padding: 20px 13px;

  background-color: #393d42;
  background-image: conic-gradient(#393d42, #2f3031, #3c4147, #2d3641, #393d42);
  border-radius: 4rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: white;
  font-family: "Agdasima", sans-serif;
  font-size: 19px;
  letter-spacing: 3.5px;

  transition: all 0.3 ease-in-out;
  opacity: 0; /* Initial state */
  animation: handleOpacity 1s ease-in-out forwards; /* Add forwards */
  animation-delay: 2s; /* Delay the start of the animation */
}

.the-best > span {
  z-index: 2;
}

.hero-title {
  display: grid;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  align-content: center;
  font-family: Oswald;
  font-size: 6rem;
  color: white;
  width: 80%;
}
.title-one {
  text-align: start;
  width: 100%;
}
.title-two {
  text-align: end;
  margin-right: 5rem;
}

.hero-subtitle {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  color: white;
  font-family:  "Jost", sans-serif;
}

.hero-name{
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}
.text-animated{
  transition: all 1s ease;
}
.text-animated:hover{
  color: #a54141;
  -webkit-text-stroke-color: rgb(255, 0, 0);

}

.hero-btn {
  display: flex;
  padding-top: 0.55rem;
  gap: 2rem;
  font-weight: normal !important;
  justify-content: center;
  color: white;
  background-color: transparent;
  width: 9rem;
  height: 2.5rem;
  border: 2px solid #f38302;
  transition: all 1s;
  margin-left: auto;
  margin-right: auto;
}
.hero-btn:hover {
  background-color: #f38302;
  border: none;
  cursor: pointer;
  color: black;
  padding-top: 0.7rem;
  transform: scale(1.2);
}
/* LEFT-H Keyframes */
@keyframes handleOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* right side */
.btnone {
  position: absolute;
  right: 0;
  top: 2rem;
  background-color: #ff8800;
  color: rgb(0, 0, 0);
  filter: brightness(1.5rem);
  width: 40%;
  margin-top: 10px;
  font-size: 0.9rem;
  transition: all 1s;
  padding: 0.5rem;
  font-weight: bold;
  border: 4px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
}

.btnone:hover {
  transform: scale(1.15);
}

.hero-img {
  position: absolute;
  top: 7rem;
  right: -24%;
  width: 34rem;
}
.hero-back {
  position: absolute;
  top: 6.5rem;
  right: 17rem;
  z-index: -1;
  width: 15rem;
}

.calories {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns */
  grid-template-rows: auto auto; /* Automatically adjust row height */
  gap: 1rem;
  background-color: #b1a9a2;
  border-radius: 5px;
  padding: 1rem;
  width: fit-content;
  position: absolute;
  top: 32rem;
  right: 28rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);  /* Add shadow */
}
.calories > img {
  width: 3rem;
}
.calories > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.calories > div > :nth-child(1) {
  color: var(--gray);
}
.calories > div > :nth-child(2) {
  color: white;
  font-size: 1.5rem;
}


@media screen and (max-width: 768px) {
  .hero {
    flex-direction: column;
  }
  .hero-blur
  {
    width: 14rem;
  }
  .left-h {
    align-items: center;
    width: 100%;
    margin-top: 0vh;
  }
  .hero-title{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .the-best {
    margin-top: 0;
    font-size: small;
    position: relative;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  .best-point {
    display: none;
  }
   .title-container{
    margin-left: 0px;
   }
   .title-one{
    transform: translateY(0);
    font-size: 2.5rem;
   }
   .title-two{
    font-size: 2.5rem;
   }
  .hero-text {
    font-size: xx-large;
    align-items: center;
    justify-content: center;
  }
  .hero-text > div:nth-of-type(3) {
    font-size: small;
    font-weight: 200;
    letter-spacing: 1px;
    text-align: center;
  }
  .hero-btns{
   justify-content: center;
  }
  .figures > div > span:nth-of-type(1) {
    font-size: large;
  }
  .figures > div > span:nth-of-type(2) {
    font-size: small;
  }
  .right-h {
    position: relative;
    background: none;
  }
  .heart-rate {
    left: 1rem;
    top: 1rem;
  }
  .calories {
    position: relative;
    top: 2rem;
    left: 2rem;
  }
  .calories>img{
    width: 2rem;
  }
  .calories > div > :nth-child(2) {
    color: white;
    font-size: 1rem;
  }
  .hero-img {
    position: relative;
    align-self: center;
    align-self: center;
    width: 100vw;
  }
  .hero-back{
    /* position: relative; */
    width: 15rem;
    left: 2rem;
    top: 0rem;
  }
}
