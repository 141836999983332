.header{
    display: flex;
    position: relative;
    padding: 0.5rem;
    padding: 15px;
}

.logo{
    width: 10rem;
    height: 5rem;
}

.header-menu{
    list-style: none;
    display: flex;
    gap: 2rem;
    color: white;
    margin-left: auto;
    margin-right: 25%;
    align-self: flex-end;
    height: 2rem;
}


.header-menu>li {
    display: flex; /* Enable flexbox */
    align-items: center; /* Vertically center text */
    justify-content: center;
    border-radius: 15px;
    border: 3px solid transparent;
    transition: all 0.5s ease-in-out;
    color: white;
}

.header-menu>li:hover{
    cursor: pointer;
    padding-inline: 10px;
    border-radius: 0px;
    border: 2.2px solid #f38302;
}

@media screen and (max-width: 767px) {
    .header-menu>li:hover{
        cursor: pointer;
        color: var(--orange);
    }
    
    .header>:nth-child(2)
    {
        position: fixed;
        right: 2rem;
        z-index: 99;
    }
    .header-menu{
        flex-direction: column;
        background-color: var(--appColor);
        padding: 2rem;
    }
}
